<template>
<div>
  <el-row>
      <el-col>
        <app-navigation :title="$t('security')"></app-navigation>
      </el-col>
    </el-row>
  <div class="layout-settings">


    <div class="form-title">{{$t("settings.rss.rss-settings")}}<span  style="float: right; cursor: pointer" @click="onSave"><i class="fa fa-upload"></i> {{ $t('settings.general.save') }}</span></div>

    <el-form label-position="left" label-width="550px" class="form-settings">

      <el-form-item :label="$t('settings.rss.polling-period')" label-width="150px">
        <el-input type="number" style="width:150px" :min="10" @input="onPollingPeriodChanged" v-model="pollingPeriod" :placeholder="$t('settings.rss.polling-period')">
          <template slot="append">{{$t("settings.rss.min")}}</template>
        </el-input>
      </el-form-item>
      <hr>
      <div v-for="category in categories" :key="category.value">
        <h5 >{{ category.title }}</h5>
        <div v-if="countCategory(category.value)" style="text-align: left">
          <div v-for="rss in rssByCategory(category.value)" :key="rss.title" >
            <el-form-item label-width="0px" style="width:100%">
              <el-checkbox v-model="rss.enabled" style="margin-bottom:5px;width:30%" >
                <div ><i class="fas fa-rss-square fa-lg"></i> {{ rss.title }}</div>
              </el-checkbox>
              <el-button  @click="deleteSource(rss)" v-if="rss.category==='other'" style="display: inline-block; margin-left:50px" icon="far fa-trash-alt" />
            </el-form-item>
          </div>
        </div>
        <hr>
      </div>

      <el-form ref="rssForm" :model="newRss" label-position="right" :inline="true"  style="margin-top: 50px; border: 1px solid #eee; padding: 0px 10px 10px 10px; border-radius: 6px">
        <h5 style="margin-top: 10px;">{{ $t('settings.rss.add-new-rss') }}</h5>

        <el-form-item :label="$t('settings.rss.title')" prop="title" :rules="[{required: true}]">
          <el-input type="text" v-model="newRss.title" :placeholder="$t('settings.rss.type-title')">
          </el-input>
        </el-form-item>

        <el-form-item :label="$t('settings.rss.url')" prop="url" :rules="[{required: true}]">
          <el-input style="width: 250px" type="text" v-model="newRss.url" :placeholder="$t('settings.rss.type-url')"></el-input>
        </el-form-item>

        <el-button icon="el-icon-plus" type="primary" @click="addRss">{{ $t('settings.rss.add') }}</el-button>
      </el-form>

    </el-form>
  </div>
</div>
</template>

<script>
  import Navigation from '@/components/Navigation';
  export default {
    name: "RSSSettings",
    components: {
      'app-navigation': Navigation
    },

    data: function(){
      return {
        loading:false,
        pollingPeriod:10,
        newRss: {
          title: "",
          url: "",
          enabled: true,
          category: "other"
        },
        categories: [
          { title: this.$t('settings.rss.categories[0]'), value: "news" },
          { title: this.$t('settings.rss.categories[1]'), value: "business" },
          { title: this.$t('settings.rss.categories[2]'), value: "technology" },
          { title: this.$t('settings.rss.categories[3]'), value: "sport" },
          { title: this.$t('settings.rss.categories[4]'), value: "economy" },
          { title: this.$t('settings.rss.categories[5]'), value: "other" },
        ],
       rssList:[
          {
            title: "Newyork Times",
            url: "http://rss.nytimes.com/services/xml/rss/nyt/Economy.xml",
            category: "economy",
            enabled: false
          },
          {
            title: "Newyork Times",
            url: "http://rss.nytimes.com/services/xml/rss/nyt/Business.xml",
            category: "business",
            enabled: false
          },
          {
            title: "Newyork Times",
            url: "http://rss.nytimes.com/services/xml/rss/nyt/Technology.xml",
            category: "technology",
            enabled: false
          },         
          {
            title: "Newyork Times",
            url: "http://rss.nytimes.com/services/xml/rss/nyt/HomePage.xml",
            category: "news",
            enabled: false
          },
          {
            title: "Newyork Times",
            url: "http://rss.nytimes.com/services/xml/rss/nyt/Sports.xml",
            category: "sport",
            enabled: false
          },
          {
            title: "CNN Türk",
            url: "https://www.cnnturk.com/feed/rss/all/news",
            category: "news",
            enabled: false
          }, 
          {
            title: "CNN Türk",
            url: "https://www.cnnturk.com/feed/rss/bilim-teknoloji/news",
            category: "technology",
            enabled: false
          },   
          {
            title: "CNN Türk",
            url: "https://www.cnnturk.com/feed/rss/spor/news",
            category: "sport",
            enabled: false
          }, 
          {
            title: "CNN Türk",
            url: "https://www.cnnturk.com/feed/rss/ekonomi/news",
            category: "economy",
            enabled: false
          },   
          {
            title: "BBC Türkçe",
            url: "http://www.bbc.co.uk/turkce/index.xml",
            category: "news",
            enabled: false
          },     
          {
            title: "CNN",
            url: "http://rss.cnn.com/rss/cnn_latest.rss",
            category: "news",
            enabled: false
          },
          {
            title: "CNN",
            url: "http://rss.cnn.com/rss/edition_technology.rss",
            category: "technology",
            enabled: false
          },  
          {
            title: "CNN",
            url: "http://rss.cnn.com/rss/edition_sport.rss",
            category: "sport",
            enabled: false
          },    
          {
            title: "CNN",
            url: "http://rss.cnn.com/rss/money_news_international.rss",
            category: "business",
            enabled: false
          },      
          {
            title: "BBC News",
            url: "http://feeds.bbci.co.uk/news/rss.xml",
            category: "news",
            enabled: false
          },   
          {
            title: "BBC News",
            url: "http://feeds.bbci.co.uk/news/business/rss.xml",
            category: "business",
            enabled: false
          },   
          {
            title: "BBC News",
            url: "http://feeds.bbci.co.uk/news/technology/rss.xml",
            category: "technology",
            enabled: false
          },                                                                                                                                                        
          ] 
      }
    },

    methods: {

      onPollingPeriodChanged(value){
          if(value < 10)
            this.pollingPeriod = 10
      },

      rssByCategory(category){
        let rss = this.rssList.filter(rss => rss.category == category)
        return rss
      },

      deleteSource(source){
        this.rssList.splice(this.rssList.indexOf(source), 1);
      },

      countCategory(category){
        return this.rssList.filter(rss => rss.category == category).length
      },

      addRss(){
        this.$refs.rssForm.validate(valid => {
          if (valid) {
            this.rssList.push({...this.newRss})
            this.newRss = {enable: true, category: "other"}
          }
        })
      },

      onSave: function () {
        this.$store.dispatch("editRssSettings",{pollingPeriod: this.pollingPeriod,
          rssSources: this.rssList}).then(response => {
          this.$message({ type: "success", message: this.$t(response.message) })
        }).catch(reason => {
          this.$message({ type: "error", message: this.$t(reason) })
        })
      }
    },
    created(){
      this.$store.dispatch("getRssSettings").then(message => {
        this.loading = false
        var rssSettings = this.$store.getters.rssSettings;
        console.log("RSS -> " + JSON.stringify(rssSettings));
      if(rssSettings && rssSettings.rssSources && rssSettings.rssSources.length > 0){
        console.log("RSS Listesi ALndı")
        this.rssList = rssSettings.rssSources
      }

      if(rssSettings && rssSettings.pollingPeriod){
        this.pollingPeriod = rssSettings.pollingPeriod
      }

      }).catch(reason => {
        this.loading = false
      })
    }    
  }
</script>

<style scoped>
  @import "../css/general.css";
</style>