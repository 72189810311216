var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            [_c("app-navigation", { attrs: { title: _vm.$t("security") } })],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "layout-settings" },
        [
          _c("div", { staticClass: "form-title" }, [
            _vm._v(_vm._s(_vm.$t("settings.rss.rss-settings"))),
            _c(
              "span",
              {
                staticStyle: { float: "right", cursor: "pointer" },
                on: { click: _vm.onSave }
              },
              [
                _c("i", { staticClass: "fa fa-upload" }),
                _vm._v(" " + _vm._s(_vm.$t("settings.general.save")))
              ]
            )
          ]),
          _c(
            "el-form",
            {
              staticClass: "form-settings",
              attrs: { "label-position": "left", "label-width": "550px" }
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("settings.rss.polling-period"),
                    "label-width": "150px"
                  }
                },
                [
                  _c(
                    "el-input",
                    {
                      staticStyle: { width: "150px" },
                      attrs: {
                        type: "number",
                        min: 10,
                        placeholder: _vm.$t("settings.rss.polling-period")
                      },
                      on: { input: _vm.onPollingPeriodChanged },
                      model: {
                        value: _vm.pollingPeriod,
                        callback: function($$v) {
                          _vm.pollingPeriod = $$v
                        },
                        expression: "pollingPeriod"
                      }
                    },
                    [
                      _c("template", { slot: "append" }, [
                        _vm._v(_vm._s(_vm.$t("settings.rss.min")))
                      ])
                    ],
                    2
                  )
                ],
                1
              ),
              _c("hr"),
              _vm._l(_vm.categories, function(category) {
                return _c("div", { key: category.value }, [
                  _c("h5", [_vm._v(_vm._s(category.title))]),
                  _vm.countCategory(category.value)
                    ? _c(
                        "div",
                        { staticStyle: { "text-align": "left" } },
                        _vm._l(_vm.rssByCategory(category.value), function(
                          rss
                        ) {
                          return _c(
                            "div",
                            { key: rss.title },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { "label-width": "0px" }
                                },
                                [
                                  _c(
                                    "el-checkbox",
                                    {
                                      staticStyle: {
                                        "margin-bottom": "5px",
                                        width: "30%"
                                      },
                                      model: {
                                        value: rss.enabled,
                                        callback: function($$v) {
                                          _vm.$set(rss, "enabled", $$v)
                                        },
                                        expression: "rss.enabled"
                                      }
                                    },
                                    [
                                      _c("div", [
                                        _c("i", {
                                          staticClass: "fas fa-rss-square fa-lg"
                                        }),
                                        _vm._v(" " + _vm._s(rss.title))
                                      ])
                                    ]
                                  ),
                                  rss.category === "other"
                                    ? _c("el-button", {
                                        staticStyle: {
                                          display: "inline-block",
                                          "margin-left": "50px"
                                        },
                                        attrs: { icon: "far fa-trash-alt" },
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteSource(rss)
                                          }
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _c("hr")
                ])
              }),
              _c(
                "el-form",
                {
                  ref: "rssForm",
                  staticStyle: {
                    "margin-top": "50px",
                    border: "1px solid #eee",
                    padding: "0px 10px 10px 10px",
                    "border-radius": "6px"
                  },
                  attrs: {
                    model: _vm.newRss,
                    "label-position": "right",
                    inline: true
                  }
                },
                [
                  _c("h5", { staticStyle: { "margin-top": "10px" } }, [
                    _vm._v(_vm._s(_vm.$t("settings.rss.add-new-rss")))
                  ]),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("settings.rss.title"),
                        prop: "title",
                        rules: [{ required: true }]
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t("settings.rss.type-title")
                        },
                        model: {
                          value: _vm.newRss.title,
                          callback: function($$v) {
                            _vm.$set(_vm.newRss, "title", $$v)
                          },
                          expression: "newRss.title"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("settings.rss.url"),
                        prop: "url",
                        rules: [{ required: true }]
                      }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "250px" },
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t("settings.rss.type-url")
                        },
                        model: {
                          value: _vm.newRss.url,
                          callback: function($$v) {
                            _vm.$set(_vm.newRss, "url", $$v)
                          },
                          expression: "newRss.url"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-plus", type: "primary" },
                      on: { click: _vm.addRss }
                    },
                    [_vm._v(_vm._s(_vm.$t("settings.rss.add")))]
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }